body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  /*font-family: profile-pro, sans-serif;*/
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  /*font-family: profile-pro, sans-serif;*/
    font-family: 'Lato', sans-serif;
}
h1,h2,h3,h4,h5,h6,label {
/*  font-family: "cooper-black-std", serif;*/
    font-family: 'Lato', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
