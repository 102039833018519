:root {
	--in-word-wrong-spot-color: #ffd700;
	--game-board-square-border-color: #959896;
}




.App {
  text-align: center;
  background-color: #FFF;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.nav .logo-container img {
	max-height: 50px;
}
.logo-container {
    position: relative;
}

#loading-animation {
	justify-content: center;
}

ul{
  padding-left: 0;
}

.text-color-0 {
  color: #007BC2;
}
.text-color-1 { 
  color: #fff;
}
.background-color-0 {
  background-color: #fff;
}
.background-color-1 {
  background-color: #007BC2;
}


.guessed-word {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 10px;
}

.guessed-word p{
    flex: 1;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    background-color: #fff;
    color: #000;
    display: flex;
    border: 1px solid var(--game-board-square-border-color);
    justify-content: center;
    font-size: 34px;
    align-items: center;
    margin: 0;
    border-radius: 5px;
    aspect-ratio: 1/1;
}

p[data-status="in-word"]{
  background-color: var(--in-word-wrong-spot-color);
  border-color: var(--in-word-wrong-spot-color);
  color: #000;
}

p[data-status="correct"]{
  background-color: #6DA34D;
  border-color: #6DA34D;
  color: #fff;
}

.game-completion-box-wrapper.show{
  display: flex;
}

.game-completion-box p{
  margin-top: 0px;
  margin-bottom: 8px;
}

#error{
  color: red;
  position: absolute;
  bottom: 0;
  margin: 0;
  opacity: 0;
}

#error.show{
  opacity: 1;
}

.game-info h1{
  text-transform: capitalize;
  margin-bottom: 0;
}

.game-info .magic-letter{
  background-color: #000;
  display: inline;
  width: auto;
  width: 90px;
  height: 90px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size:50px;
  margin: 0 0 30px;
  text-transform: uppercase;
}

.correct-words-list h2{
  margin-bottom: 10px;
}

.correct-word{
  margin: 0;
  font-size: 20px;
  text-transform: capitalize;
  text-align: left;
  margin: 0 0 7px;
}

#emoji.show{
  opacity: 1;
  transition: all .2s ease-in-out;
}

.times-wrapper{
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 16px;
    margin-bottom: 26px;
}

.clipboard-notice{
  padding: 10px;
  font-size: 14px;
  background-color: #0e0e0e;
  color: #e3e3e3;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20%;
  max-width: 200px;
  border-bottom: 2px solid #747474;
  opacity: 0;
  transition: all .4s ease-out;
}

.clipboard-notice.fadeUpOut{
  top: 19%;
  opacity: 1;
}

#facebook-button.disable{
  pointer-events: none;
}

.attempts{
  text-transform: lowercase;
  font-size: 13px;
  margin-left: 8px;
  position: relative;
  top: -2px;
  border-radius: 50px;
  width: 27px;
  height: 27px;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.backspace {
    transition: all 0.12s ease;
}

.backspace img{
    transition: all 0.12s ease;
    object-fit: contain;
    width: 80%;
}

.enter{
  padding: 5px 8.5px 0;
  width: 60px !important;
}

.enter img{
  max-height: 18px;
  position: relative;
  object-fit: contain;
  width: 100%;
}

.row2{
  position: relative;
  top: 2px;
}

.row3{
  position: relative;
  top: 5px;
}

.game-completion-box-wrapper .completeTime {
	text-transform: capitalize;
}
.game-completion-box-wrapper #close-game-completion-box-wrapper {
	position: relative;
	cursor: pointer; 
}
.game-completion-box-wrapper #close-game-completion-box {
	position: absolute;
	right: -24px;
	top: 0px;
}

.keyboard {
    width: 95%;
}

.keyboard .correct-letter-correct-spot-color {
	background-color: #6DA34D;
}


.keyboard .wrong-letter-color { 
	background-color: #cccccc;
}


.keyboard .correct-letter-wrong-spot-color { 
	background-color: var(--in-word-wrong-spot-color);
}

@media screen and (max-width: 420px){
  .keyboard {
    width: 100%;
  }
  .keyboard .enter {
    font-size: 12px;
  }
}